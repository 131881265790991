import { Schedule as ClockIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { FormHelperText, InputAdornment } from "@mui/material";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { toTimeFormat } from "../../../helpers/date-helpers";
import { ChangeHandler, TimePicker as TTimePicker } from "../types/Modify";
import { PickerChild } from "./PickerChild";

interface Props<D extends object> {
  config: Omit<TTimePicker<D>, "controltype">;
  handleChange: ChangeHandler;
}

const borderStyles: SxProps<Theme> = {
  borderRadius: 0,
};

export const TimePicker = <D extends object>({
  config,
  handleChange,
}: Props<D>) => {
  const {
    name,
    label,
    required,
    disabled,
    value,
    error,
    helperText,
    outputFormatter = toTimeFormat,
  } = config;
  const timeValue =
    typeof value === "string" && value ? DateTime.fromISO(value) : null;

  return (
    <>
      <MuiTimePicker
        renderInput={(props) => (
          <PickerChild
            name={name}
            {...props}
            required={required}
            error={error}
          />
        )}
        value={timeValue}
        disabled={disabled}
        InputProps={{
          title: name,
          id: name,
          sx: [borderStyles],
          endAdornment: (
            <InputAdornment position="end">
              <ClockIcon />
            </InputAdornment>
          ),
        }}
        onChange={(time) => {
          if (!time) return;
          const timeString = outputFormatter(time);
          console.log(timeString);
          handleChange({
            target: {
              name,
              value: timeString,
            },
          });
        }}
        label={label}
      />
      <FormHelperText error={error} children={helperText} />
    </>
  );
};
