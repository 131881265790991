import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export const NextPageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M16.1486371,13.5256393 L12.3051194,9.68262772 C12.2503295,9.62771129 12.1870617,9.6 12.1153161,9.6 C12.0442031,9.6 11.9809353,9.62771129 11.9255127,9.68262772 L8.08250119,13.5256393 C8.02771129,13.5809353 8,13.6442031 8,13.7154426 C8,13.7870617 8.02771129,13.8503295 8.08250119,13.905246 L8.49475405,14.3173723 C8.54967048,14.3722887 8.61293826,14.4 8.68455739,14.4 C8.75579691,14.4 8.81906469,14.3722887 8.87436073,14.3173723 L12.1153161,11.0764169 L15.3569041,14.3173723 C15.411694,14.3727949 15.4749618,14.4 15.5462013,14.4 C15.6178204,14.4 15.6810882,14.3722887 15.7360046,14.3173723 L16.1486371,13.905246 C16.2035535,13.8503295 16.2307587,13.7870617 16.2307587,13.7154426 C16.2307587,13.6442031 16.2035535,13.5809353 16.1486371,13.5256393 Z"
        id="up-arrow-copy-4"
        transform="translate(12.115379, 12.000000) rotate(-270.000000) translate(-12.115379, -12.000000) "
      ></path>
    </SvgIcon>
  );
};
