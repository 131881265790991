import type { SxProps, Theme } from "@mui/material";
import { FormHelperText, TextField } from "@mui/material";
import type { ChangeHandler, FormInput } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<FormInput<D>, "controltype">;
  handleChange: ChangeHandler;
  children?: JSX.Element | JSX.Element[];
}

const inputStyles: SxProps<Theme> = {
  borderRadius: 0,
};

const selectStyles: SxProps<Theme> = {
  textAlign: "left",
};

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.grey,
});

export const Input = <D extends object>({
  config: input,
  handleChange,
}: Props<D>): JSX.Element => {
  const { error, helperText, ...config } = input;
  const value = config.value ?? "";

  const computedInputStyles = [inputStyles];

  return (
    <>
      <TextField
        {...config}
        error={error}
        value={value}
        onChange={handleChange}
        InputLabelProps={{
          htmlFor: config.name,
        }}
        InputProps={{
          ...config.InputProps,
          id: config.name,
          title: config.name,
          sx: [...computedInputStyles],
        }}
        SelectProps={{
          ...config.SelectProps,
          sx: [selectStyles, ...computedInputStyles],
        }}
        sx={[rootStyles]}
        variant="outlined"
        size="small"
      />
      <FormHelperText error={error} children={helperText} />
    </>
  );
};
