import type { IconButtonProps, SxProps, Theme } from "@mui/material";
import { IconButton } from "@mui/material";

interface Props extends IconButtonProps {
  children: JSX.Element;
}

const rootStyles: SxProps<Theme> = (theme) => ({
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  margin: theme.spacing("0", 0.25),
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    opacity: "50%",
  },
});

export const PaginationIcon = ({ children, sx = [], ...props }: Props) => {
  return (
    <IconButton
      {...props}
      size="small"
      sx={[rootStyles, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </IconButton>
  );
};
