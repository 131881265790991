import { useParams } from "react-router-dom";
import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { api } from "../../services/conversations.service";
import { RouteProps } from "../../types";
import type { ConversationData } from "../../types/documents/Conversation";
import { ModifyContainer } from "../general/ModifyContainer";
import { componentConfiguration, constraints } from "./modifyConfig";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const ConversationModify: Component = ({ mode, ...routeProps }) => {
  const { clientId, id: caseId } = useParams<{
    clientId: string;
    id: string;
  }>();

  return (
    <ModifyContainer<ConversationData>
      {...routeProps}
      mode={mode}
      api={api}
      queryKey="conversationDetails"
      initialData={{
        clientId,
        caseId,
        conversationId: "",
        tenantId: "",
        date: "",
        notes: "",
        appointmentInformation: "",
        actionRequired: "",
        actionCompleted: "",
        actionCompletedDate: "",
        furtherAction: "",
        userId: "",
        created: "",
        updated: "",
      }}
      componentConfiguration={componentConfiguration}
      constraints={constraints}
      redirectPath={(conversationId) =>
        paths.conversations.view(clientId, caseId, conversationId)
      }
    />
  );
};
