import { Grid } from "@mui/material";
import { useForm } from "../../hooks/general/useForm";
import { reportStrings as strings } from "../../resources/strings/reports";
import { ReportFilters } from "../../types/documents/Report";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { DatePicker } from "../general/controls/DatePicker";

interface Props {
  filters: ReportFilters;
  handleFilterChange: (name: keyof ReportFilters, value: any) => void;
  clearFilters: () => void;
}
type Component = (props: Props) => JSX.Element;

export const ReportFilterBar: Component = ({
  filters,
  handleFilterChange,
  clearFilters,
}) => {
  const [currentFilters, handleChange] = useForm(filters);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    handleFilterChange("start", currentFilters.start);
    handleFilterChange("end", currentFilters.end);
  };

  const renderField = (name: keyof ReportFilters, label: string) => (
    <Grid item xs>
      <BaseControl control={false}>
        <DatePicker
          config={{
            name,
            label,
            value: currentFilters[name] ?? "",
            outputFormatter: (date) => date.toISODate(),
          }}
          handleChange={handleChange}
        />
      </BaseControl>
    </Grid>
  );

  return (
    <form onSubmit={onSubmit}>
      <Grid container alignItems="center" spacing={2} role="searchbox">
        {renderField("start", strings.header.start)}
        {renderField("end", strings.header.end)}
        <Grid item xs={2}>
          <BaseControl control={false}>
            <Button type="submit" label={strings.labels.search} />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <Button
              label={strings.labels.reset}
              variant="outlined"
              onClick={clearFilters}
            />
          </BaseControl>
        </Grid>
      </Grid>
    </form>
  );
};
