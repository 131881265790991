import { paths } from "../../navigation/paths";
import { userStrings as strings } from "../../resources/strings/users";
import type { Mode } from "../general/types/Modify";

export const crumbs = (mode: Mode) => [
  {
    text: strings.header.users,
    to: paths.users.list,
  },
  {
    text:
      mode === "create" ? strings.header.createUser : strings.header.editUser,
  },
];
