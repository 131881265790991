import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-reports";
import type { QueryFunction, QueryResult } from "../types/API";
import type { ReportData, ReportFilters } from "../types/documents/Report";

const query: QueryFunction<ReportData, ReportFilters> = async (
  body,
  signal
) => {
  const { propertyName, ...dateRange } = body.query ?? {};
  let path = "/";
  if (propertyName) path += propertyName;

  const options: AxiosRequestConfig = { signal, params: dateRange };

  const { data } = await axios.get<QueryResult<ReportData>>(path, options);
  return data;
};

export const api = {
  query,
};
