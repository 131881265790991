import { Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { CreateEntityButton } from "../../components/general/CreateEntityButton";
import { useUsers } from "../../hooks/general/useUsers";
import { useTagGroup } from "../../hooks/tags/queries/useTagGroup";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { caseStrings as strings } from "../../resources/strings/cases";
import { api } from "../../services/cases.service";
import { RouteProps } from "../../types";
import type { CaseData } from "../../types/documents";
import { TableContainer } from "../general/TableContainer";
import { tableColumns } from "./tableColumns";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const CaseList: Component = ({ ...routeProps }) => {
  const history = useHistory();

  const [caseStatusOptions] = useTagGroup([], "caseStatus");
  const [caseTypeOptions] = useTagGroup([], "caseType");
  const [users] = useUsers();

  const { id: clientId } = useParams<{ id: string }>();
  const clickHandler = ({ clientId, caseId }: CaseData) => {
    history.push(paths.cases.view(clientId, caseId));
  };
  return (
    <div>
      <TableContainer<CaseData>
        {...routeProps}
        queryKey="cases"
        api={api}
        inlineLoader
        tableColumns={tableColumns(caseStatusOptions, caseTypeOptions, users)}
        clickHandler={clickHandler}
        filters={{
          clientId,
        }}
        children={
          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CreateEntityButton
              permissions={routeProps.permissions}
              config={{
                label: strings.labels.newCase,
                path: paths.cases.create(clientId),
                userAccess: {
                  entity: appStrings.entities.cases,
                  permission: appStrings.permissions.create,
                },
              }}
            />
          </Box>
        }
      />
    </div>
  );
};
