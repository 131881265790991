import { Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { CreateEntityButton } from "../../components/general/CreateEntityButton";
import { useUsers } from "../../hooks/general/useUsers";
import { useTagGroup } from "../../hooks/tags/queries/useTagGroup";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { conversationStrings as strings } from "../../resources/strings/conversations";
import { api } from "../../services/conversations.service";
import { RouteProps } from "../../types";
import { ConversationData } from "../../types/documents/Conversation";
import { TableContainer } from "../general/TableContainer";
import { tableColumns } from "./tableColumns";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const ConversationList: Component = ({ ...routeProps }) => {
  const history = useHistory();

  const [appointmentInfoOptions] = useTagGroup([], "appointmentInformation");
  const [actionRequiredOptions] = useTagGroup([], "actionRequired");
  const [users] = useUsers();

  const { id: clientId, childId: caseId } = useParams<{
    id: string;
    childId: string;
  }>();
  const clickHandler = ({
    clientId,
    caseId,
    conversationId,
  }: ConversationData) => {
    history.push(paths.conversations.view(clientId, caseId, conversationId));
  };
  return (
    <div>
      <TableContainer<ConversationData>
        {...routeProps}
        queryKey="conversations"
        api={api}
        inlineLoader
        tableColumns={tableColumns(
          appointmentInfoOptions,
          actionRequiredOptions,
          users
        )}
        clickHandler={clickHandler}
        filters={{
          caseId,
        }}
        children={
          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <CreateEntityButton
              permissions={routeProps.permissions}
              config={{
                label: strings.labels.newConversation,
                path: paths.conversations.create(clientId, caseId),
                userAccess: {
                  entity: appStrings.entities.conversations,
                  permission: appStrings.permissions.create,
                },
              }}
            />
          </Box>
        }
      />
    </div>
  );
};
