export const reportStrings = {
  header: {
    report: "Report",
    count: "Count",
    change: "Change",
    start: "Start",
    end: "End",
  },
  labels: {
    export: "Export",
    search: "Search",
    reset: "Reset",
  },
};

export const reportFields = {
  entityType: "Entity Counts",

  // Client fields
  benefits: "Benefits",
  householdIncome: "Household Income",
  disabilities: "Disability",
  newsletterSubscription: "Newsletter Subscription",
  propertyStatus: "Property Status",
  councilTaxBand: "Council Tax Band",
  epcRating: "EPC Rating",
  fuelPovertyIndicators: "Fuel Poverty Indicator",

  // Case Fields
  referredFrom: "Referred From",
  energyAdviceInterventions: "Energy Advice Interventions",
  fuelBankVouchers: "Fuel Bank Vouchers",
  savings: "Savings",
  fuelType: "Fuel Type",
  additionalSupport: "Additional Support",
};
