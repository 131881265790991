import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import type {
  ChangeHandler,
  RadioButtonGroup as TRadioButtonGroup,
} from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TRadioButtonGroup<D>, "controltype">;
  handleChange: ChangeHandler;
}

export const RadioButtonGroup = <D extends object>({
  config: input,
  handleChange,
}: Props<D>): JSX.Element => {
  const { label, options, disabled, required, error, helperText, ...config } =
    input;

  return (
    <FormControl
      component="fieldset"
      error={error}
      fullWidth
      title={config.name}
    >
      <FormLabel
        component="legend"
        required={required}
        focused={false}
        sx={{ textAlign: "start" }}
      >
        {label}
      </FormLabel>
      <RadioGroup onChange={handleChange} {...config}>
        {options.map((value) => (
          <FormControlLabel
            key={value}
            label={value}
            value={value}
            name={config.name}
            control={<Radio disabled={disabled} size="small" />}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={error} children={helperText} />
    </FormControl>
  );
};
