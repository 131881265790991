import { useParams } from "react-router-dom";
import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { api } from "../../services/cases.service";
import { RouteProps } from "../../types";
import type { CaseData } from "../../types/documents";
import { ModifyContainer } from "../general/ModifyContainer";
import { componentConfiguration, constraints } from "./modifyConfig";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const CaseModify: Component = ({ mode, ...routeProps }) => {
  const { id: clientId } = useParams<{ id: string }>();
  return (
    <ModifyContainer<CaseData>
      {...routeProps}
      mode={mode}
      api={api}
      queryKey="caseDetails"
      initialData={{
        caseId: "",
        sortKey: "",
        clientId,
        tenantId: "",
        userId: "",
        created: "",
        updated: "",

        status: "",
        type: [],
        project: [],
        energyAdviceInterventions: "",
        referredFrom: "",
        contact: "",
        additionalSupport: [],
        suppliers: [],
        referralDate: "",
        referralNotes: "",
        surveys: "",
        fuelType: [],
        notes: "",
        fuelBankVouchers: "",
        fuelBankVoucherQuantity: "0",
        energyAppointments: "",
        savings: "",
      }}
      componentConfiguration={componentConfiguration}
      constraints={constraints}
      redirectPath={(caseId) => paths.cases.view(clientId, caseId)}
    />
  );
};
