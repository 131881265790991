import type { Column } from "react-table";
import { formatDateTime } from "../../helpers/date-helpers";
import { findValue } from "../../helpers/dropdown-helpers";
import { caseStrings as strings } from "../../resources/strings/cases";
import type { DropdownOptions } from "../../types";
import type { CaseData, UserData } from "../../types/documents";

export const tableColumns = (
  caseStatusOptions: DropdownOptions,
  caseTypeOptions: DropdownOptions,
  users: UserData[]
): Column<CaseData>[] => [
  {
    id: "status",
    Header: strings.labels.status,
    accessor: ({ status }) => findValue(status, caseStatusOptions),
  },
  {
    id: "type",
    Header: strings.labels.type,
    accessor: ({ type, typeOther }) =>
      type
        .map((type) =>
          type === strings.options.other
            ? typeOther
            : findValue(type, caseTypeOptions)
        )
        .join(", "),
  },
  {
    id: "vouchers",
    Header: strings.labels.fuelBankVoucherQuantity,
    accessor: "fuelBankVoucherQuantity",
  },
  {
    id: "notes",
    Header: strings.labels.notes,
    accessor: "notes",
    maxWidth: 100,
  },
  {
    id: "created",
    Header: strings.labels.created,
    accessor: ({ created }) => formatDateTime(created),
  },
  {
    id: "updated",
    Header: strings.labels.updated,
    accessor: ({ updated }) => formatDateTime(updated),
  },
  {
    id: "createdBy",
    Header: strings.labels.createdBy,
    accessor: ({ userId }) => {
      const user = users.find((user) => user.userId === userId);
      if (!user) return strings.labels.unknown;
      return `${user.forename} ${user.surname}`;
    },
  },
];
