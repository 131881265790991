import type { SxProps, Theme } from "@mui/material";
import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  Hidden,
  Typography,
  styled,
} from "@mui/material";
import bgImage from "../../assets/reap-bg-image.jpg";
import logo from "../../assets/reap-logo.svg";
import { authStrings as strings } from "../../resources/strings/auth";

interface Props {
  children: JSX.Element | JSX.Element[];
}
type Component = (props: Props) => JSX.Element;

interface LayoutProps {
  children: JSX.Element;
  bgImage: string;
  logo: string;
  legalText: string;
}

const rootStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
};

const imageStyles: SxProps<Theme> = {
  height: "100%",
  width: "auto",
  backgroundSize: "cover",
};

const logoStyles: SxProps<Theme> = (theme) => ({
  height: theme.spacing(25),
  width: "100%",
  marginX: "auto",
  "> img": {
    objectFit: "contain",
  },
});

const container: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
  height: "100%",
};

const contentStyles: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  height: "100%",
  width: "100%",
};

const Div = styled("div")({});

const PageLayout = ({ children, bgImage, logo, legalText }: LayoutProps) => {
  return (
    <Div sx={[rootStyles]}>
      <Box display="flex" flexDirection="row" height="100vh" maxHeight="100vh">
        <Grid container>
          <Grid item sm={12} md={4} sx={[container]}>
            <div />
            <Grid item xs={8} sx={[contentStyles]}>
              <Avatar sx={[logoStyles]} src={logo} variant="square" />
              {children}
            </Grid>
            <div />
            <Typography variant="caption">{legalText}</Typography>
          </Grid>
          {/* Hide background image on smaller screen sizes */}
          <Hidden mdDown>
            <Grid item md>
              <CardMedia sx={[imageStyles]} image={bgImage} />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Div>
  );
};

export const AuthWrapper: Component = ({ children }) => {
  return (
    <PageLayout bgImage={bgImage} logo={logo} legalText={strings.text.legal}>
      <Box display="flex" flexDirection="column" justifyContent={"center"}>
        {children}
      </Box>
    </PageLayout>
  );
};
