import type { DeleteFunction } from "../../types/API";

import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { logger } from "../../helpers/log-helpers";
import { HooksLogger } from "../hooks-logger";

const hooksLogger = new HooksLogger("useDeleteMutation");

export const useDeleteMutation = (
  deleteFn: DeleteFunction,
  id: string,
  childId?: string,
  redirectPath?: string
) => {
  const history = useHistory();

  const mutation = useMutation({
    mutationFn: () => deleteFn(id, childId),
    onSuccess: () => {
      hooksLogger.success();
      redirectPath && history.push(redirectPath);
    },
    onError: (e) => {
      const error = logger.error(e);
      hooksLogger.error(error);
    },
  });

  return {
    deleteInProgress: mutation.isLoading,
    deleteFn: mutation.mutateAsync,
  };
};
