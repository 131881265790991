import { api } from "../../../services/permissions.service";
import { CognitoUser } from "../../../types";

import { useQuery } from "@tanstack/react-query";
import { HooksLogger } from "../../hooks-logger";

const hooksLogger = new HooksLogger("useUserPermissions");

export const useUserPermissions = (user: CognitoUser | null) => {
  const { data, isLoading, error, isFetching } = useQuery(
    ["userPermissions", user],
    api.read,
    {
      staleTime: Infinity,
      enabled: Boolean(user),
      onSuccess: (result) => {
        hooksLogger.success(result);
      },
      onError: (result) => {
        hooksLogger.error(result);
      },
    }
  );
  const permissions = data ? data : {};
  const loading = isLoading && isFetching;
  return { permissions, loading, error };
};
