import type {
  ComponentConfiguration,
  Content,
} from "../../components/general/types/Modify";
import type { ClientData } from "../../types/documents";

import { ClientBreadcrumbHeader } from "../../components/clients/ClientBreadcrumbHeader";
import { appStrings } from "../../resources/strings/app";
import { clientStrings as strings } from "../../resources/strings/clients";
import { CaseList } from "../cases/CaseList";

const required: (keyof ClientData)[] = [
  "title",
  "forename",
  "surname",
  "dateOfBirth",
  "address1",
  "city",
  "code",
  "country",
  "disabilities",
  "benefits",
  "propertyStatus",
  "householdIncome",
  "noOfBedrooms",
  "councilTaxBand",
  "epcRating",
  "fuelPovertyIndicators",
  "householdWithChildrenUnder5",
  "newsletterSubscription",
  "gdpr",
  "clientMandate",
  "thirdPartyMandate",
];

export const constraints: Partial<Record<keyof ClientData, any>> =
  required.reduce(
    (req, field) => ({
      ...req,
      [field]: {
        presence: {
          allowEmpty: false,
          message: `^${strings.labels[field]} is required`,
        },
      },
    }),
    {}
  );

export const componentConfiguration: ComponentConfiguration<ClientData> = ({
  data,
}) => [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: ClientBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
  {
    key: "details",
    content: [
      {
        controltype: "header",
        text: strings.header.clientDetails,
      },
      {
        controltype: "dropdown",
        name: "title",
        label: strings.labels.title,
        required: true,
        tagConfig: { tagGroup: "title" },
        other: {
          name: "titleOther",
          label: strings.labels.titleOther,
        },
      },
      {
        controltype: "input",
        name: "forename",
        label: strings.labels.forename,
        required: true,
      },
      {
        controltype: "input",
        name: "surname",
        label: strings.labels.surname,
        required: true,
      },
      {
        controltype: "input",
        name: "email",
        label: strings.labels.email,
      },
      {
        controltype: "datepicker",
        name: "dateOfBirth",
        label: strings.labels.dateOfBirth,
        required: true,
      },
      {
        controltype: "input",
        name: "mobileNo",
        label: strings.labels.mobileNo,
      },
      {
        controltype: "input",
        name: "landlineNo",
        label: strings.labels.landlineNo,
      },
      {
        controltype: "header",
        text: strings.header.address,
      },
      {
        controltype: "input",
        name: "address1",
        label: strings.labels.address1,
        required: true,
      },
      {
        controltype: "input",
        name: "address2",
        label: strings.labels.address2,
      },
      {
        controltype: "input",
        name: "city",
        label: strings.labels.city,
        required: true,
      },
      {
        controltype: "input",
        name: "state",
        label: strings.labels.state,
      },
      {
        controltype: "input",
        name: "code",
        label: strings.labels.code,
        required: true,
      },
      {
        controltype: "dropdown",
        name: "country",
        label: strings.labels.country,
        required: true,
        tagConfig: { tagGroup: "country" },
      },
      {
        controltype: "header",
        text: strings.header.householdDetails,
      },
      {
        controltype: "radiobutton",
        name: "disabilities",
        label: "Disabilities",
        options: ["Yes", "No"],
        row: true,
        required: true,
        md: 12,
      },
      ...(data.disabilities === "Yes"
        ? [
            {
              controltype: "input",
              name: "disabilityDetails",
              label: strings.labels.disabilityDetails,
              multiline: true,
              rows: 3,
              md: 12,
            } as Content<ClientData>,
          ]
        : []),
      {
        controltype: "dropdown",
        name: "benefits",
        label: strings.labels.benefits,
        required: true,
        SelectProps: { multiple: true },
        tagConfig: { tagGroup: "benefits" },
        other: {
          name: "benefitsOther",
          label: strings.labels.benefitsOther,
        },
      },
      {
        controltype: "dropdown",
        name: "householdIncome",
        label: strings.labels.householdIncome,
        required: true,
        tagConfig: { tagGroup: "householdIncome" },
      },
      {
        controltype: "dropdown",
        name: "propertyStatus",
        label: strings.labels.propertyStatus,
        required: true,
        tagConfig: { tagGroup: "propertyStatus" },
        other: {
          name: "propertyStatusOther",
          label: strings.labels.propertyStatusOther,
        },
      },
      {
        controltype: "dropdown",
        name: "noOfBedrooms",
        label: strings.labels.noOfBedrooms,
        required: true,
        tagConfig: { tagGroup: "noOfBedrooms" },
      },
      {
        controltype: "dropdown",
        name: "councilTaxBand",
        label: strings.labels.councilTaxBand,
        required: true,
        tagConfig: { tagGroup: "councilTaxBand" },
      },
      {
        controltype: "dropdown",
        name: "epcRating",
        label: strings.labels.epcRating,
        required: true,
        tagConfig: { tagGroup: "epcRating" },
      },
      {
        controltype: "dropdown",
        name: "fuelPovertyIndicators",
        label: strings.labels.fuelPovertyIndicators,
        required: true,
        SelectProps: { multiple: true },
        tagConfig: { tagGroup: "fuelPovertyIndicators" },
        other: {
          name: "fuelPovertyIndicatorsOther",
          label: strings.labels.fuelPovertyIndicatorsOther,
        },
      },
      {
        controltype: "radiobutton",
        name: "householdWithChildrenUnder5",
        label: strings.labels.householdWithChildrenUnder5,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      {
        controltype: "radiobutton",
        name: "newsletterSubscription",
        label: strings.labels.newsletterSubscription,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      {
        controltype: "radiobutton",
        name: "gdpr",
        label: strings.labels.gdpr,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      {
        controltype: "radiobutton",
        name: "clientMandate",
        label: strings.labels.clientMandate,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      {
        controltype: "radiobutton",
        name: "thirdPartyMandate",
        label: strings.labels.thirdPartyMandate,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
    ],
  },
  {
    key: "casesList",
    modes: ["update", "view"],
    userAccess: {
      entity: appStrings.entities.cases,
      permission: appStrings.permissions.read,
    },
    content: [
      {
        controltype: "header",
        text: strings.header.clientCases,
      },
      {
        controltype: "custom",
        Component: CaseList,
        control: false,
        md: 12,
      },
    ],
  },
];
