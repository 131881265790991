export const userStrings = {
  header: {
    users: "Users",
    userDetails: "User Details",
    accountDetails: "Account Details",
    userInfo: "User Information",
    createUser: "Create User",
    editUser: "Edit User",
  },
  labels: {
    email: "Email address",
    roleId: "User Role",
    forename: "Forename",
    surname: "Surname",
    created: "Created",
    updated: "Updated",
    createUser: "New User",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
  },
};
