export const getValidationProps = (
  field: string,
  validationResults: Record<string, string[]> | null = {}
): Partial<{ error: boolean; helperText: string }> => {
  if (validationResults === null || !validationResults[field]) return {};
  return {
    error: true,
    helperText: validationResults[field].join(", "),
  };
};
