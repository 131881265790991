import { Loader } from "../components/general/Loader";
import { Layout } from "../components/layout";
import { useAuth } from "../hooks/authentication/useAuth";
import { useUserPermissions } from "../hooks/permissions/queries/useUserPermissions";
import { Routes } from "../navigation/routes";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const Wrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="App">{children}</div>
);

const App = () => {
  const { user, checkedAuth } = useAuth();
  const { permissions, loading: loadingPermissions } = useUserPermissions(user);

  const isLoading = [!checkedAuth, loadingPermissions].some(
    (condition) => condition
  );
  if (isLoading) {
    return (
      <Wrapper>
        <Loader active />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ReactQueryDevtools />
      <Layout permissions={permissions} user={user}>
        <Routes user={user} permissions={permissions} />
      </Layout>
    </Wrapper>
  );
};

export default App;
