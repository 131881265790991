import { Button as UIButton } from "@mui/material";
import type { Button as TButton } from "../types/Modify";

import { useModifyContext } from "../../../context/ModifyContext";

interface Props {
  config: Omit<TButton, "controltype">;
}
type Component = (props: Props) => JSX.Element;

export const Button: Component = ({ config: input }) => {
  const { handleModeSwitch } = useModifyContext();

  const { label, buttonaction, ...config } = input;
  const buttonConfig = Object.assign({}, config);
  if (buttonaction) {
    switch (buttonaction) {
      case "switch":
        buttonConfig.type = "button";
        buttonConfig.onClick = () => {
          handleModeSwitch();
        };
        break;
      default:
        break;
    }
  }

  return (
    <UIButton type="submit" {...buttonConfig}>
      {label}
    </UIButton>
  );
};
