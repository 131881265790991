const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

const error = (error: unknown, logText = "ERROR") => {
  const message = getErrorMessage(error);
  console.log(logText, message);
  return message;
};

export const logger = {
  error,
};
