type LoggerFn = (...args: any[]) => void;
export class HooksLogger {
  private isProd = process.env.NODE_ENV === "production";

  constructor(private name: string) {}

  info: LoggerFn = (...args) => {
    if (this.isProd) return;

    console.log(`%cINFO %c${this.name}`, "color:blue", "color:grey", ...args);
  };
  request: LoggerFn = (...args) => {
    console.log(
      `%cREQUEST %c${this.name}`,
      "color:cyan",
      "color:grey",
      ...args
    );
  };
  success: LoggerFn = (...args) => {
    console.log(
      `%cSUCCESS %c${this.name}`,
      "color:green",
      "color:grey",
      ...args
    );
  };
  error: LoggerFn = (...args) => {
    console.log(`%cERROR %c${this.name}`, "color:red", "color:grey", ...args);
  };
}
