import { useIsAuthorised } from "../../hooks/authentication/useIsAuthorised";
import type { Permissions, UserAccess } from "../../types";

interface BaseProps {
  children: JSX.Element | JSX.Element[];
}

interface ShowProps extends BaseProps {
  show: boolean;
}

interface Props extends UserAccess, BaseProps {
  // permissions may end up coming from store rather than
  // passing in each time
  userPermissions: Permissions | null;
}

type ShowComponent = (props: ShowProps) => JSX.Element;
type Component = (props: Props) => JSX.Element;

export const ShowIfAuthorised: Component = ({
  children,
  userPermissions,
  entity,
  permission,
}) => {
  const isAuthorised = useIsAuthorised(userPermissions, entity, permission);
  return <ShowIf show={isAuthorised}>{children}</ShowIf>;
};

export const ShowIf: ShowComponent = ({ show, children }) => {
  return <>{show ? children : null}</>;
};
