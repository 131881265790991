export const conversationStrings = {
  header: {
    conversations: "Conversations",
    cases: "Cases",
    clients: "Clients",
    caseConversations: "Case Conversations",
    caseDetails: "Case Details",
    clientDetails: "Client Details",
    conversationDetails: "Conversation Details",
    createConversation: "Create Conversation",
    editConversation: "Edit Conversation",
    deleteConversation: "Delete Conversation",
  },
  labels: {
    tenantId: "Tenant ID",
    clientId: "Client ID",
    caseId: "Case ID",
    conversationId: "Conversation ID",
    userId: "User ID",
    date: "Date / Time",
    time: "Time",
    notes: "Notes",
    appointmentInformation: "Appointment Information",
    appointmentInformationOther:
      "Other Appointment Information - Please Specify",
    actionRequired: "Action Required",
    actionRequiredOther: "Other Action - Please Specify",
    actionCompleted: "Action Completed",
    actionCompletedDate: "Date Completed",
    furtherAction: "Further Action",
    created: "Created",
    createdBy: "Created By",
    updated: "Updated",
    unknown: "Unknown",

    save: "Save",
    reset: "Reset",
    edit: "Edit",
    newConversation: "New Conversation",
    delete: "Delete",
    confirmDelete:
      "Are you sure you want to delete this conversation? This action cannot be undone.",

    conversationsCount: "No. of Conversations",
  },
  options: {
    yesNo: ["Yes", "No"],
    other: "Other",
  },
};
