import { useHistory } from "react-router-dom";
import { useRoles } from "../../hooks/permissions/queries/useRoles";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { userStrings as strings } from "../../resources/strings/users";
import { api } from "../../services/users.service";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { TableContainer } from "../general/TableContainer";
import { tableColumns } from "./tableColumns";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const UserList: Component = ({ ...routeProps }) => {
  const history = useHistory();
  const clickHandler = ({ userId }: UserData) => {
    history.push(paths.users.view(userId));
  };
  const [roles] = useRoles();

  return (
    <div>
      <TableContainer<UserData>
        {...routeProps}
        queryKey="users"
        api={api}
        header={strings.header.users}
        tableColumns={tableColumns(roles)}
        createButtonConfig={{
          label: strings.labels.createUser,
          userAccess: {
            entity: appStrings.entities.users,
            permission: appStrings.permissions.create,
          },
          path: paths.users.create,
        }}
        clickHandler={clickHandler}
      />
    </div>
  );
};
