import { MenuItem, Stack } from "@mui/material";
import { DownIcon } from "../../../assets";
import { displayDropdownValue } from "../../../helpers/dropdown-helpers";
import { useTagGroup } from "../../../hooks/tags/queries/useTagGroup";
import type { ChangeHandler, Dropdown as TDropdown } from "../types/Modify";
import { Input } from "./Input";

interface Props<D extends object> {
  config: Omit<TDropdown<D>, "controltype">;
  handleChange: ChangeHandler;
}

const loadingValue = "loading";
const loadingState = [
  { label: "Loading Tags...", value: loadingValue, disabled: true },
];

const otherOption = { label: "Other", value: "Other" };

export const Dropdown = <D extends object>({
  config: input,
  handleChange,
}: Props<D>): JSX.Element => {
  const { options: inputOptions = [], tagConfig, other, ...config } = input;

  const [options, loading] = useTagGroup(inputOptions, tagConfig?.tagGroup);

  const displayOther = Array.isArray(config.value)
    ? config.value.includes(otherOption.value)
    : config.value === otherOption.value;

  const renderOptions = (dropdownOptions: typeof options) => {
    if (other) {
      dropdownOptions.push(otherOption);
    }

    return dropdownOptions.map((option, index) => (
      <MenuItem
        key={`${option.value}-${index}`}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <>
      <Stack spacing={0.75}>
        <Input<D>
          config={{
            ...config,
            select: true,
            SelectProps: {
              ...config.SelectProps,
              IconComponent: DownIcon,
              renderValue: (data) => displayDropdownValue(data, options),
            },
            children: renderOptions(loading ? loadingState : options),
          }}
          handleChange={handleChange}
        />

        {displayOther && (
          <Input<D>
            config={{ ...config, ...other }}
            handleChange={handleChange}
          />
        )}
      </Stack>
    </>
  );
};
