import { QueryClientConfig } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./assets/index.css";
import App from "./components/App";
import { Wrappers } from "./components/Wrappers";
import reportWebVitals from "./utils/reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

root.render(
  <StrictMode>
    <Wrappers queryClientConfig={queryClientConfig}>
      <App />
    </Wrappers>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
