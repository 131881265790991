import "@fontsource/open-sans";
import { createTheme, responsiveFontSizes } from "@mui/material";
import * as colors from "@mui/material/colors";

// colour palette
const primary = "#3c8e2a";
const secondary = "#3c8e2a";
const lightgrey = "#fafafa";
const grey = "#d8d8d8";
const darkgrey = "#666666";
const altgrey = "#999999";

// typography
const font = "'Open Sans', sans-serif";

let theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: colors.common.white,
    },
    secondary: {
      main: secondary,
      contrastText: colors.common.white,
    },
    background: {
      default: colors.common.white,
    },
    common: {
      grey,
      lightgrey,
      darkgrey,
      altgrey,
    },
  },
  typography: {
    fontFamily: font,
  },
});

theme = responsiveFontSizes(theme);

export { theme };
