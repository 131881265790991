import { useHistory } from "react-router-dom";
import type { Permissions, UserAccess } from "../../types";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Button } from "./Button";

interface Props {
  config: {
    label: string;
    userAccess: UserAccess;
    path: string;
    state?: object;
  };
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const CreateEntityButton: Component = ({
  permissions,
  config: { userAccess, path, label, state },
}) => {
  const history = useHistory();
  const onClick = () => {
    if (state) {
      history.push(path, state);
    } else {
      history.push(path);
    }
  };
  return (
    <>
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        <Button
          color="primary"
          variant="contained"
          label={label}
          onClick={onClick}
        />
      </ShowIfAuthorised>
    </>
  );
};
