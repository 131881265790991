import type { DateTimeFormatOptions, LocaleOptions } from "luxon";
import { DateTime } from "luxon";

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

export const formatDateTime = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
    timeStyle: "short",
  });
  return formattedString;
};

export const formatDate = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export const toDateFormat = (date: DateTime | null) =>
  date?.toISO({ suppressMilliseconds: true }) || "";

export const toTimeFormat = (date: DateTime | null) =>
  date?.toISOTime({ suppressSeconds: true, includeOffset: false }) || "";
