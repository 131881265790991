import type { SxProps, Theme } from "@mui/material";
import { Button, FormHelperText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/general/Loader";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { Link } from "../../components/general/controls/Link";
import { useForm } from "../../hooks/general/useForm";
import { forgotPassword, forgotPasswordSubmit } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import type { CognitoUser } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}
type Component = (props: Props) => JSX.Element;

const spacerStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2, "0"),
});

export const PasswordReset: Component = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [codeSent, setCodeSent] = useState(false);

  const [formData, handleChange] = useForm({
    username: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();

  useEffect(() => {
    if (user) history.push(paths.root);
    if (success) history.push(paths.auth.login);
  }, [user, success, history]);

  const onRequestCode: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPassword(formData.username);

      setCodeSent(true);
      setLoading(false);
      setError("");
    } catch (e) {
      // Continue on the "normal" flow even if account/email address doesn't exist
      // to help limit brute force attempts
      setCodeSent(true);
      setLoading(false);
      setSuccess(false);
    }
  };

  const onSubmitPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPasswordSubmit(
        formData.username,
        formData.code,
        formData.newPassword
      );

      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e: any) {
      setCodeSent(false);
      setLoading(false);
      setSuccess(false);
      setError(e ? e : strings.errors.changePassword);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return (
      <BaseControl control={false} margin>
        {control}
      </BaseControl>
    );
  };

  const renderPasswordForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.headers.completePassword}
        </Typography>
        <form noValidate onSubmit={onSubmitPassword} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.code,
                name: "code",
                label: strings.labels.code,
                type: "password",
                fullWidth: true,
                autoComplete: "code",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.newPassword,
                name: "newPassword",
                label: strings.labels.newPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "new-password",
              }}
              handleChange={handleChange}
            />
          )}
          {renderControl(
            <Input
              config={{
                value: formData.confirmNewPassword,
                name: "confirmNewPassword",
                label: strings.labels.confirmNewPassword,
                type: "password",
                fullWidth: true,
                autoComplete: "confirm-password",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={[
              !formData.code,
              !formData.newPassword,
              !formData.confirmNewPassword,
              formData.newPassword !== formData.confirmNewPassword,
            ].includes(true)}
            sx={[spacerStyles]}
          />
        </form>
      </>
    );
  };

  const renderRequestCodeForm = () => {
    return (
      <>
        <Typography variant="button" color="primary">
          {strings.labels.resetPassword}
        </Typography>
        <Typography>{strings.text.receiveCode}</Typography>
        <form noValidate onSubmit={onRequestCode} autoComplete="off">
          {renderControl(
            <Input
              config={{
                value: formData.username,
                name: "username",
                label: strings.labels.email,
                fullWidth: true,
                autoComplete: "username",
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.submit}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.username}
            sx={[spacerStyles]}
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </form>
        <Link
          key="login"
          config={{
            text: strings.labels.returnToLogin,
            to: paths.auth.login,
            sx: [spacerStyles],
          }}
        />
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline />;
    }

    if (codeSent) return renderPasswordForm();
    return renderRequestCodeForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
