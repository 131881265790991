import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";
import type { Header as THeader } from "../types/Modify";

interface Props {
  config: Omit<THeader, "controltype">;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  color: "secondary.main",
  borderBottom: theme.spacing(0.125, "solid"),
  fontWeight: "bolder",
  padding: theme.spacing(0.5, 0),
});

export const Header: Component = ({ config }) => {
  const { text, ...props } = config;
  return (
    <Typography
      {...props}
      sx={[rootStyles]}
      variant="button"
      align="left"
      role="heading"
    >
      {text}
    </Typography>
  );
};
