import {
  QueryClient,
  QueryClientProvider,
  type QueryClientConfig,
} from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { theme } from "../styles/theme";

interface Props {
  children: JSX.Element;
  queryClientConfig?: QueryClientConfig;
}
type Component = (props: Props) => JSX.Element;

const locale = DateTime.local().locale;

// component for Router, store, providers etc.
// will be used for main app as well as any unit tests
export const Wrappers: Component = ({ children, queryClientConfig }) => {
  const queryClient = new QueryClient(queryClientConfig);
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterLuxon}
              adapterLocale={locale}
            >
              {children}
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Router>
  );
};
