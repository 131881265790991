import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";

interface Props {
  text: string;
  styles?: SxProps<Theme>;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  fontWeight: "bolder",
  color: theme.palette.common.darkgrey,
});

export const Header: Component = ({ text, styles }) => {
  return (
    <Typography
      variant="h6"
      sx={[rootStyles, ...(Array.isArray(styles) ? styles : [styles])]}
    >
      {text}
    </Typography>
  );
};
