import { useDeleteMutation } from "../../hooks/general/useDeleteMutation";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { clientStrings as strings } from "../../resources/strings/clients";
import { api } from "../../services/clients.service";
import type { ClientData } from "../../types/documents";
import { BreadcrumbHeader } from "../general/BreadcrumbHeader";
import { DeleteEntityButton } from "../general/DeleteEntityButton";
import { Loader } from "../general/Loader";
import { SaveEntityButtons } from "../general/SaveEntityButtons";
import type { CustomProps } from "../general/types/Modify";
import { crumbs } from "./crumbs";

interface Props extends CustomProps<ClientData> {}
type Component = (props: Props) => JSX.Element;

export const ClientBreadcrumbHeader: Component = (props) => {
  const { data, mode, permissions } = props;
  const { clientId } = data;

  const { deleteInProgress, deleteFn } = useDeleteMutation(
    api.delete,
    clientId,
    "",
    paths.clients.list
  );

  return (
    <BreadcrumbHeader
      crumbs={crumbs(mode)}
      children={
        <Loader active={deleteInProgress} inline>
          <>
            {mode !== "create" && (
              <DeleteEntityButton
                permissions={permissions}
                config={{
                  label: strings.labels.delete,
                  heading: strings.header.deleteClient,
                  message: strings.labels.confirmDelete,
                  entity: appStrings.entities.clients,
                }}
                handleDelete={deleteFn}
              />
            )}
            <SaveEntityButtons
              {...props}
              config={{
                entity: appStrings.entities.clients,
                editLabel: strings.labels.edit,
                saveLabel: strings.labels.save,
                resetLabel: strings.labels.reset,
              }}
            />
          </>
        </Loader>
      }
    />
  );
};
