// Cognito config
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const COGNITO_SIGNIN_URL = process.env.REACT_APP_COGNITO_SIGNIN_URL;
export const COGNITO_SIGNOUT_URL = process.env.REACT_APP_COGNITO_SIGNOUT_URL;

// Endpoints
export const PERMISSIONS_ENDPOINT = `${process.env.REACT_APP_PERMISSIONS_ENDPOINT}/permissions`;
export const REPORTS_ENDPOINT = `${process.env.REACT_APP_REPORTS_ENDPOINT}/report`;
export const CONVERSATIONS_ENDPOINT = `${process.env.REACT_APP_CONVERSATIONS_ENDPOINT}/conversations`;
export const CASES_ENDPOINT = `${process.env.REACT_APP_CASES_ENDPOINT}/cases`;
export const CLIENTS_ENDPOINT = `${process.env.REACT_APP_CLIENTS_ENDPOINT}/clients`;
export const USERS_ENDPOINT = `${process.env.REACT_APP_USERS_ENDPOINT}/users`;
export const TAGS_ENDPOINT = `${process.env.REACT_APP_TAGS_ENDPOINT}/tags`;

// App config
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
