import axios from "../libs/axios/axios-permissions";
import type { QueryFunction, QueryResult } from "../types/API";
import type { RoleData } from "../types/documents/Role";

const query: QueryFunction<RoleData> = async (_body, signal?) => {
  const { data } = await axios.get<QueryResult<RoleData>>("/roles", {
    signal,
  });
  return data;
};

export const api = {
  query,
};
