import { Grid, Snackbar } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "../../components/general/Loader";
import { ModifyComponent } from "../../components/general/ModifyComponent";
import type {
  ChangeHandler,
  ComponentConfiguration,
  Mode,
} from "../../components/general/types/Modify";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { logger } from "../../helpers/log-helpers";
import { HooksLogger } from "../../hooks/hooks-logger";
import { RouteProps } from "../../types";
import type {
  ApiFunctionBody,
  CreateFunction,
  ReadFunction,
  UpdateFunction,
  UpdateResult,
} from "../../types/API";
import validate from "../../validation";

const hooksLogger = new HooksLogger("ModifyContainer/submitData");

interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  api: {
    read: ReadFunction<D>;
    create: CreateFunction<D>;
    update: UpdateFunction<D>;
  };
  queryKey: string;
  initialData: D;
  componentConfiguration: ComponentConfiguration<D>;
  mode: Mode;
  constraints: object;
  redirectPath: string | ((id: string) => string);
  loading?: boolean;
}

export const ModifyContainer = <D extends { [key: string]: unknown }>({
  children,
  api,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  loading: propsLoading = false,
  queryKey,
  user,
}: Props<D>) => {
  const history = useHistory();
  const { id, childId } = useParams<{ id?: string; childId?: string }>();
  const [mode, setMode] = useState<Mode>(() => inputMode);

  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);

  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [message, setMessage] = useState("");

  const queryClient = useQueryClient();
  const { isFetching } = useQuery<{ item: D }>({
    queryKey: [queryKey, id],
    placeholderData: { item: initialData },
    enabled: Boolean(id) && inputMode !== "create",
    queryFn: async ({ signal }) => api.read(id ?? "", childId, signal),
    onSuccess: ({ item }) => {
      setFormData(item);
      setInitialData(item);
      hooksLogger.success(item);
    },
    onError: (e) => {
      const error = logger.error(e);
      hooksLogger.error(error);
    },
  });

  const submitMutation = useMutation({
    mutationFn: async (body: ApiFunctionBody<D>): Promise<UpdateResult> => {
      let apiFunction = api.update;
      if (mode === "create") {
        apiFunction = api.create;
      }
      return apiFunction(body);
    },
    onSuccess: async ({ id }) => {
      hooksLogger.success();
      handleModeSwitch(true, id);
      setMessage("Data submitted successfully");
      await queryClient.invalidateQueries([queryKey]);
    },
    onError: (e) => {
      const error = logger.error(e);
      hooksLogger.error(error);
    },
  });

  const handleModeSwitch = useCallback(
    (success?: boolean, redirectId?: string) => {
      switch (mode) {
        case "update":
          setMode("view");
          break;
        case "view":
          setMode("update");
          break;
        case "create":
          let path = "";
          if (typeof redirectPath === "string") {
            path = redirectPath;
          } else if (redirectId) {
            path = redirectPath(redirectId);
          }

          if (success && path) history.push(path);
          break;
        default:
          break;
      }
    },
    [mode, history, redirectPath]
  );

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  const handleChange: ChangeHandler = (e) => {
    const { target } = e;
    const { name, value } = target;
    if (!name) return;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitData = async () => {
    const body = {
      eventType: mode,
      payload: formData,
    };
    hooksLogger.request("Submitting form data");
    submitMutation.reset();

    setValidationResults(null);
    try {
      await submitMutation.mutateAsync(body);
    } catch (e) {
      logger.error(e);
    }
  };

  const validateForm = () => {
    const results = validate(formData, constraints) ?? {};
    const isValid = !Object.keys(results).length;
    if (!isValid) {
      setValidationResults(results);
      return;
    }
    submitData();
  };

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [submitMutation.isLoading, isFetching, propsLoading].some(
    (l) => l
  );

  return (
    <ModifyContextProvider value={{ handleModeSwitch }}>
      <Loader active={isLoading}>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={!!message}
            onClose={() => setMessage("")}
            autoHideDuration={6000}
            message={message}
          />
          <ModifyComponent<D>
            permissions={permissions}
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            handleChange={handleChange}
            handleReset={handleReset}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
            user={user}
          />
          {renderChildren()}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
