import type { SxProps, Theme } from "@mui/material";
import { Breadcrumbs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { NextPageIcon } from "../../assets";
import { FormHeader } from "./FormHeader";
import { Header } from "./Header";

export type Crumb = {
  text: string;
  to?: string;
};

interface Props {
  crumbs: Crumb[];
  children?: JSX.Element | JSX.Element[];
}
type Component = (props: Props) => JSX.Element;

const linkStyles: SxProps<Theme> = {
  color: "primary.main",
  textDecoration: "underline",
};

export const BreadcrumbHeader: Component = ({ crumbs, children }) => {
  const { state } = useLocation();
  const header = (
    <Breadcrumbs separator={<NextPageIcon fontSize="large" />} role="region">
      {crumbs.map(({ text, to }, index) => {
        if (!to) return <Header key={index} text={text} />;
        return (
          <Link
            to={{
              pathname: to,
              state,
            }}
            key={index}
          >
            <Header text={text} styles={linkStyles} />
          </Link>
        );
      })}
    </Breadcrumbs>
  );
  return <FormHeader header={header} children={children} />;
};
