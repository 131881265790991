import { useCallback, useSyncExternalStore } from "react";
import type { HubCallback } from "../../libs/amplify";
import { Hub } from "../../libs/amplify";
import { getIdToken } from "../../libs/auth";
import { CognitoUser } from "../../types";
import { HooksLogger } from "../hooks-logger";

import { useQuery, useQueryClient } from "@tanstack/react-query";

const logger = new HooksLogger("useAuthUser.ts");

type IdToken = { [key: string]: any };

const buildAuthUser = (userDetails: IdToken): CognitoUser => ({
  username: userDetails["cognito:username"],
  tenantId: userDetails["custom:tenantId"],
  roleId: userDetails["custom:roleId"],
  email: userDetails["email"],
  userId: userDetails["sub"],
  firstName: userDetails["given_name"],
  surname: userDetails["family_name"],
});

const getUserProfile = async (): Promise<CognitoUser | null> => {
  try {
    const userDetails = await getIdToken();
    const authUser = buildAuthUser(userDetails);
    logger.success(userDetails);
    return Promise.resolve(authUser);
  } catch (e) {
    return Promise.resolve(null);
  }
};

export const useAuthUser = () => {
  useAuthHub();
  const { data, isFetched, isLoading } = useQuery({
    queryKey: ["userProfile"],
    queryFn: getUserProfile,
    staleTime: Infinity,
  });
  const user = data ? data : null;
  return { user, checkedAuth: isFetched, loading: isLoading };
};

const useAuthHub = () => {
  const queryClient = useQueryClient();
  const subscribe = useCallback(() => {
    const callback: HubCallback = async (authEvent) => {
      switch (authEvent.payload.event) {
        case "signIn":
          await queryClient.invalidateQueries(["userProfile"]);
          await queryClient.invalidateQueries(["userPermissions"]);
          break;
        case "signOut":
          await queryClient.invalidateQueries();
          break;
      }
    };
    Hub.listen("auth", callback);
    return () => {
      Hub.remove("auth", callback);
    };
  }, [queryClient]);

  useSyncExternalStore(
    subscribe,
    () => {},
    () => {}
  );
};
