import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { getToken } from "../auth";

const onRequest = async (
  initialConfig: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const config = Object.assign(
    {
      headers: {},
    },
    initialConfig
  );

  const token = await getToken();
  config.headers.Authorization = token;

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const interceptors = {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
};

export default interceptors;
