import type { Mode } from "../../components/general/types/Modify";
import { useRoles } from "../../hooks/permissions/queries/useRoles";
import { paths } from "../../navigation/paths";
import { api } from "../../services/users.service";
import { RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { ModifyContainer } from "../general/ModifyContainer";
import { componentConfiguration, constraints } from "./modifyConfig";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const UserModify: Component = ({ mode, ...routeProps }) => {
  const [roles, loading] = useRoles();

  return (
    <ModifyContainer<UserData>
      {...routeProps}
      mode={mode}
      api={api}
      queryKey="userDetails"
      initialData={{
        userId: "",
        email: "",
        roleId: "",
        forename: "",
        surname: "",
        created: "",
        updated: "",
      }}
      componentConfiguration={componentConfiguration(mode, roles)}
      constraints={constraints}
      redirectPath={paths.users.view}
      loading={loading}
    />
  );
};
