import { appStrings } from "../../resources/strings/app";
import { userStrings as strings } from "../../resources/strings/users";
import type { UserData } from "../../types/documents";
import { BreadcrumbHeader } from "../general/BreadcrumbHeader";
import { SaveEntityButtons } from "../general/SaveEntityButtons";
import type { CustomProps } from "../general/types/Modify";
import { crumbs } from "./crumbs";

interface Props extends CustomProps<UserData> {}
type Component = (props: Props) => JSX.Element;

export const UserBreadcrumbHeader: Component = (props) => {
  return (
    <BreadcrumbHeader
      crumbs={crumbs(props.mode)}
      children={
        <SaveEntityButtons
          {...props}
          config={{
            entity: appStrings.entities.users,
            editLabel: strings.labels.edit,
            saveLabel: strings.labels.save,
            resetLabel: strings.labels.reset,
          }}
        />
      }
    />
  );
};
