import { paths } from "../../navigation/paths";
import { caseStrings as strings } from "../../resources/strings/cases";
import type { Mode } from "../general/types/Modify";

export const crumbs = (mode: Mode, clientId: string) => [
  {
    text: strings.header.clients,
    to: paths.clients.list,
  },
  {
    text: strings.header.clientDetails,
    to: paths.clients.view(clientId),
  },
  {
    text:
      mode === "create" ? strings.header.createCase : strings.header.editCase,
  },
];
