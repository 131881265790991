export const appStrings = {
  entities: {
    users: "users",
    clients: "clients",
    cases: "cases",
    conversations: "conversations",
    tags: "tags",
    reports: "reports",
  },
  permissions: {
    read: "read",
    create: "create",
    update: "update",
    delete: "delete",
    access: "access",
  },
  menuLabels: {
    cases: "Cases",
    clients: "Clients",
    users: "Users",
    reports: "Report",
  },
  labels: {
    signOut: "Sign Out",
    signIn: "Login",
    loading: "Loading...",
  },
};
