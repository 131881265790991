import { Redirect, Route, RouteProps } from "react-router-dom";
import { ShowIfAuthorised } from "../../components/authentication/ShowIfAuthorised";
import { paths } from "../../navigation/paths";
import { RouteProps as AppRouteProps, UserAccess } from "../../types";

export interface Props extends RouteProps, AppRouteProps {
  userAccess: UserAccess;
}
type Component = (props: Props) => JSX.Element;

export const PrivateRoute: Component = ({
  children,
  userAccess,
  permissions,
  user,
  ...props
}) => {
  // the main app component will ensure push to login when user is logged out
  if (!user) {
    return <Redirect to={paths.auth.login} />;
  }
  // now validate user has the relevant permission
  return (
    <>
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        <Route {...props}>{children}</Route>
      </ShowIfAuthorised>
    </>
  );
};
