import type {
  ComponentConfiguration,
  Mode,
} from "../../components/general/types/Modify";
import { UserBreadcrumbHeader } from "../../components/users/UserBreadcrumbHeader";
import { userStrings as strings } from "../../resources/strings/users";
import type { DropdownOptions } from "../../types";
import type { UserData } from "../../types/documents";

export const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.email} is required`,
    },
    email: true,
  },
  roleId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.roleId} is required`,
    },
  },
};

export const componentConfiguration = (
  mode: Mode,
  roles: DropdownOptions
): ComponentConfiguration<UserData> => [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: UserBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
  {
    key: "details",
    content: [
      {
        controltype: "header",
        text: strings.header.accountDetails,
      },
      {
        controltype: "input",
        name: "email",
        label: strings.labels.email,
        required: true,
        disabled: mode !== "create",
      },
      {
        controltype: "dropdown",
        name: "roleId",
        label: strings.labels.roleId,
        options: roles,
        required: true,
      },
      {
        controltype: "header",
        text: strings.header.userInfo,
      },
      {
        controltype: "input",
        name: "forename",
        label: strings.labels.forename,
      },
      {
        controltype: "input",
        name: "surname",
        label: strings.labels.surname,
      },
    ],
  },
];
