import type { RouteProps } from "../../types";
import type { ClientData } from "../../types/documents";
import type { ClientFilters } from "../../types/documents/Client";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ClientSearchBar } from "../../components/clients/ClientSearch";
import { useTagGroup } from "../../hooks/tags/queries/useTagGroup";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings/app";
import { clientStrings as strings } from "../../resources/strings/clients";
import { api } from "../../services/clients.service";
import { TableContainer } from "../general/TableContainer";
import { tableColumns } from "./tableColumns";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export const ClientList: Component = ({ ...routeProps }) => {
  const history = useHistory();

  const [caseStatusTags] = useTagGroup([], "caseStatus");
  const [caseTypeTags] = useTagGroup([], "caseType");

  const clickHandler = ({ clientId }: ClientData) => {
    history.push(paths.clients.view(clientId));
  };

  const [filters, setFilters] = useState<ClientFilters>({});

  const handleFilterChange = (name: keyof ClientFilters, value: any) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  return (
    <div>
      <TableContainer<ClientData>
        {...routeProps}
        queryKey="clients"
        api={api}
        header={strings.header.clients}
        tableColumns={tableColumns(caseStatusTags, caseTypeTags)}
        createButtonConfig={{
          label: strings.labels.createClient,
          userAccess: {
            entity: appStrings.entities.clients,
            permission: appStrings.permissions.create,
          },
          path: paths.clients.create,
        }}
        clickHandler={clickHandler}
        filters={filters as any}
        children={
          <ClientSearchBar
            filters={filters}
            handleFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        }
      />
    </div>
  );
};
