import { Link as UILink, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import type { Link as TLink } from "../types/Modify";

interface Props {
  config: Omit<TLink, "controltype">;
}
type Component = (props: Props) => JSX.Element;

export const Link: Component = ({ config }) => {
  const { text, to } = config;
  return (
    <Typography {...config}>
      <UILink color="primary" component={RouterLink} to={to} underline="always">
        {text}
      </UILink>
    </Typography>
  );
};
