import type { Column } from "react-table";
import { formatDateTime } from "../../helpers/date-helpers";
import { findValue } from "../../helpers/dropdown-helpers";
import { userStrings as strings } from "../../resources/strings/users";
import { DropdownOptions } from "../../types";
import type { UserData } from "../../types/documents";

export const tableColumns = (roles: DropdownOptions): Column<UserData>[] => [
  {
    id: "email",
    Header: strings.labels.email,
    accessor: "email",
  },
  {
    id: "forename",
    Header: strings.labels.forename,
    accessor: "forename",
  },
  {
    id: "surname",
    Header: strings.labels.surname,
    accessor: "surname",
  },
  {
    id: "role",
    Header: strings.labels.roleId,
    accessor: ({ roleId }) => findValue(roleId, roles),
  },
  {
    id: "created",
    Header: strings.labels.created,
    accessor: ({ created }) => formatDateTime(created),
  },
  {
    id: "updated",
    Header: strings.labels.updated,
    accessor: ({ updated }) => formatDateTime(updated),
  },
];
