import { createDropdownOptions } from "../../../helpers/dropdown-helpers";
import { api } from "../../../services/roles.service";
import type { RoleData } from "../../../types/documents";
import { useQuery } from "../../general/useQuery";

const { query } = api;

export const useRoles = () => {
  const [items, ...response] = useQuery<RoleData>([], "roles", query);
  const roles = createDropdownOptions(items, "roleId", "name");
  return [roles, ...response] as const;
};
