import { Typography } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/authentication/PrivateRoute";
import { CustomLogin as Login } from "../containers/authentication/CustomLogin";
import { PasswordReset } from "../containers/authentication/PasswordReset";
import { CaseModify } from "../containers/cases/CaseModify";
import { ClientList } from "../containers/clients/ClientList";
import { ClientModify } from "../containers/clients/ClientModify";
import { ConversationModify } from "../containers/conversations/ConversationModify";
import { ReportsContainer } from "../containers/reports/ReportsContainer";
import { UserList } from "../containers/users/UserList";
import { UserModify } from "../containers/users/UserModify";
import { appStrings as strings } from "../resources/strings/app";
import { RouteProps } from "../types";
import { paths } from "./paths";

type Component = (props: RouteProps) => JSX.Element;
type TRoutes = JSX.Element[];

export const Routes: Component = (props) => {
  const appRoutes: TRoutes = [
    <Route key="login" exact path={paths.auth.login}>
      <Login user={props.user} />
    </Route>,
    <Route key="passwordReset" exact path={paths.auth.passwordReset}>
      <PasswordReset user={props.user} />
    </Route>,
    <Route key="root" exact path={paths.root}>
      <Redirect to={paths.clients.list} />
    </Route>,
  ];
  const clientRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewClient"
      exact
      path={paths.clients.view(":id")}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.read,
      }}
    >
      <ClientModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createClient"
      exact
      path={paths.clients.create}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.create,
      }}
    >
      <ClientModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="listClients"
      exact
      path={paths.clients.list}
      userAccess={{
        entity: strings.entities.clients,
        permission: strings.permissions.read,
      }}
    >
      <ClientList {...props} />
    </PrivateRoute>,
  ];
  const caseRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewCase"
      exact
      path={paths.cases.view(":id", ":childId")}
      userAccess={{
        entity: strings.entities.cases,
        permission: strings.permissions.read,
      }}
    >
      <CaseModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createCase"
      exact
      path={paths.cases.create(":id")}
      userAccess={{
        entity: strings.entities.cases,
        permission: strings.permissions.create,
      }}
    >
      <CaseModify {...props} mode="create" />
    </PrivateRoute>,
  ];
  const conversationRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewConversation"
      exact
      path={paths.conversations.view(":clientId", ":id", ":childId")}
      userAccess={{
        entity: strings.entities.conversations,
        permission: strings.permissions.read,
      }}
    >
      <ConversationModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createConversation"
      exact
      path={paths.conversations.create(":clientId", ":id")}
      userAccess={{
        entity: strings.entities.conversations,
        permission: strings.permissions.create,
      }}
    >
      <ConversationModify {...props} mode="create" />
    </PrivateRoute>,
  ];
  const reportRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewReport"
      exact
      path={paths.report.view}
      userAccess={{
        entity: strings.entities.reports,
        permission: strings.permissions.read,
      }}
    >
      <ReportsContainer {...props} />
    </PrivateRoute>,
  ];
  const userRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewUser"
      exact
      path={paths.users.view(":id")}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <UserModify {...props} mode="view" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createUser"
      exact
      path={paths.users.create}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.create,
      }}
    >
      <UserModify {...props} mode="create" />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="userList"
      exact
      path={paths.users.list}
      userAccess={{
        entity: strings.entities.users,
        permission: strings.permissions.read,
      }}
    >
      <UserList {...props} />
    </PrivateRoute>,
  ];
  return (
    <Switch>
      {appRoutes}
      {clientRoutes}
      {caseRoutes}
      {conversationRoutes}
      {userRoutes}
      {reportRoutes}
      <Route key="notFound">
        <Typography>page not found</Typography>
      </Route>
    </Switch>
  );
};
