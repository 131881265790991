import { createContext, useContext } from "react";

export const createCtx = <A extends {} | null>() => {
  const ctx = createContext<A | undefined>(undefined);
  const useCtx = () => {
    const value = useContext(ctx);
    if (!value) {
      throw new Error("useCtx must be inside a Provider with a value");
    }
    return value;
  };
  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
};
