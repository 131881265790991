import axios from "../libs/axios/axios-conversations";
import type {
  DeleteFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  ReadResult,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { ConversationData } from "../types/documents/Conversation";

const query: QueryFunction<ConversationData> = async (body, signal?) => {
  const { data } = await axios.post<QueryResult<ConversationData>>(
    "/list-conversations",
    body,
    {
      signal,
    }
  );
  return data;
};

const read: ReadFunction<ConversationData> = async (
  id,
  childId = "",
  signal?
) => {
  const path = `/${id}/${childId}`;
  const { data } = await axios.get<ReadResult<ConversationData>>(path, {
    signal,
  });
  return data;
};

const update: UpdateFunction<ConversationData> = async (body) => {
  const { data } = await axios.put<UpdateResult>("/submit", body);
  return data;
};

const deleteFn: DeleteFunction = async (id, childId) => {
  await axios.delete(`/${id}/${childId}`);
};

export const api = {
  query,
  read,
  update,
  create: update,
  delete: deleteFn,
};
