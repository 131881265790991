import { BarChart, People, Person } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { paths } from "../../navigation/paths";
import { appStrings as strings } from "../../resources/strings/app";
import { UserAccess } from "../../types";

type IconType =
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
  | (() => JSX.Element);

const { entities, permissions, menuLabels } = strings;

export interface MenuItem {
  label: string;
  Icon: IconType;
  itemPermission?: UserAccess;
  link: string;
}

export const menuItems: MenuItem[] = [
  {
    label: menuLabels.clients,
    Icon: People,
    itemPermission: {
      entity: entities.clients,
      permission: permissions.read,
    },
    link: paths.clients.list,
  },
  {
    label: menuLabels.reports,
    Icon: BarChart,
    itemPermission: {
      entity: entities.reports,
      permission: permissions.read,
    },
    link: paths.report.view,
  },
  {
    label: menuLabels.users,
    Icon: Person,
    itemPermission: {
      entity: entities.users,
      permission: permissions.read,
    },
    link: paths.users.list,
  },
];
