import axios from "../libs/axios/axios-users";
import type {
  QueryFunction,
  QueryResult,
  ReadFunction,
  ReadResult,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { UserData } from "../types/documents/User";

const query: QueryFunction<UserData> = async (body, signal?) => {
  const { data } = await axios.post<QueryResult<UserData>>(
    "/list-users",
    body,
    {
      signal,
    }
  );
  return data;
};

const read: ReadFunction<UserData> = async (id, _childId, signal?) => {
  const path = `/${id}`;
  const { data } = await axios.get<ReadResult<UserData>>(path, {
    signal,
  });
  return data;
};

const update: UpdateFunction<UserData> = async (body) => {
  const { data } = await axios.put<UpdateResult>("/submit", body);
  return data;
};

export const api = {
  query,
  read,
  update,
  create: update,
};
