import type { Column } from "react-table";
import type { ReportData, ReportFilters, TagData } from "../../types/documents";

import { useState } from "react";
import { CSVLink } from "react-csv";
import { reportStrings } from "../../resources/strings/reports";
import { Button } from "../general/Button";
import { Loader } from "../general/Loader";

type TableDefinition = (
  propertyName: string,
  tags: TagData[],
  dateRange: ReportFilters
) => Column<ReportData>[];

interface Props {
  tableDefinition: TableDefinition;
  rawData: { tags: TagData[]; data: ReportData[]; header: string }[];
  filters: ReportFilters;
  fileName?: string;
}

export const CSVExportButton = ({
  tableDefinition,
  rawData,
  filters,
  fileName = "export.csv",
}: Props): JSX.Element => {
  const [exportData, setExportData] = useState<any[][]>([]);
  const [isExporting, setIsExporting] = useState(false);

  const onExport = async () => {
    setIsExporting(true);
    setExportData([]);

    const output = rawData.flatMap(({ data, header, tags }) =>
      buildTable(data, tableDefinition(header, tags, filters))
    );

    setExportData(output);
    setIsExporting(false);
  };

  const buildTable = (
    rawData: ReportData[],
    tableDefinition: Column<ReportData>[]
  ) => {
    const table: any[][] = [tableDefinition.map(({ Header }) => Header)];

    for (const row of rawData) {
      const mappedData = tableDefinition.map(({ accessor }) =>
        typeof accessor === "function"
          ? accessor(row, 0, { subRows: [], depth: 0, data: [] })
          : row[accessor as keyof ReportData]
      );

      table.push(mappedData);
    }

    // Sort by the order column, then remove it from the output
    const sorted = table
      .sort((a, b) => a[4] - b[4])
      .map((row) => row.slice(0, -1));

    return [...sorted, []];
  };

  return (
    <CSVLink
      data={exportData}
      asyncOnClick
      filename={fileName}
      onClick={(_event, done) => onExport().then(() => done(true))}
    >
      <Button
        label={
          <Loader inline text="" active={isExporting}>
            <>{reportStrings.labels.export}</>
          </Loader>
        }
      />
    </CSVLink>
  );
};
