import { SxProps, TextField, TextFieldProps, Theme } from "@mui/material";

const inputStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
});

type Props<D extends object> = TextFieldProps & {
  name: string & keyof D;
};

export const PickerChild = <D extends object>({ name, ...props }: Props<D>) => (
  <TextField
    {...props}
    variant="outlined"
    size="small"
    sx={[inputStyles]}
    InputLabelProps={{ htmlFor: name }}
  />
);
