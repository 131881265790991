import type { Mode } from "../../components/general/types/Modify";
import { paths } from "../../navigation/paths";
import { api } from "../../services/clients.service";
import { RouteProps } from "../../types";
import type { ClientData } from "../../types/documents";
import { ModifyContainer } from "../general/ModifyContainer";
import { componentConfiguration, constraints } from "./modifyConfig";

export interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const ClientModify: Component = ({ mode, ...routeProps }) => {
  return (
    <ModifyContainer<ClientData>
      {...routeProps}
      api={api}
      queryKey="clientDetails"
      mode={mode}
      initialData={{
        clientId: "",
        created: "",
        updated: "",

        title: "",
        forename: "",
        surname: "",
        email: "",
        dateOfBirth: "",
        mobileNo: "",
        landlineNo: "",

        address1: "",
        address2: "",
        city: "",
        state: "",
        code: "",
        country: "",

        benefits: [],
        householdIncome: "",
        householdDetails: "",
        disabilities: "",
        disabilityDetails: "",
        newsletterSubscription: "",
        gdpr: "",
        clientMandate: "",
        thirdPartyMandate: "",
        propertyStatus: "",
        noOfBedrooms: "",
        councilTaxBand: "",
        epcRating: "",
        householdWithChildrenUnder5: "",
        fuelPovertyIndicators: [],
      }}
      componentConfiguration={componentConfiguration}
      constraints={constraints}
      redirectPath={paths.clients.view}
    />
  );
};
