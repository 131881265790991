export const paths = {
  root: "/",
  auth: {
    login: "/login",
    passwordReset: "/passwordReset",
  },
  clients: {
    list: "/clients",
    create: "/clients/create",
    view: (id: string) => `/clients/view/${id}`,
  },
  cases: {
    create: (clientId: string) => `/cases/create/${clientId}`,
    view: (clientId: string, caseId: string) =>
      `/cases/view/${clientId}/${caseId}`,
  },
  conversations: {
    create: (clientId: string, caseId: string) =>
      `/conversations/create/${clientId}/${caseId}`,
    view: (clientId: string, caseId: string, conversationId: string) =>
      `/conversations/view/${clientId}/${caseId}/${conversationId}`,
  },
  users: {
    list: "/users",
    create: "/users/create",
    view: (id: string) => `/users/view/${id}`,
  },
  settings: {
    list: "/settings",
  },
  report: {
    view: "/reports",
  },
};
