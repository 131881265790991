import { paths } from "../../navigation/paths";
import { conversationStrings as strings } from "../../resources/strings/conversations";
import type { Mode } from "../general/types/Modify";

export const crumbs = (mode: Mode, clientId: string, caseId: string) => [
  {
    text: strings.header.clients,
    to: paths.clients.list,
  },
  {
    text: strings.header.clientDetails,
    to: paths.clients.view(clientId),
  },
  {
    text: strings.header.caseDetails,
    to: paths.cases.view(clientId, caseId),
  },
  {
    text:
      mode === "create"
        ? strings.header.createConversation
        : strings.header.editConversation,
  },
];
