import { paths } from "../../navigation/paths";
import { clientStrings as strings } from "../../resources/strings/clients";
import type { Mode } from "../general/types/Modify";

export const crumbs = (mode: Mode) => [
  {
    text: strings.header.clients,
    to: paths.clients.list,
  },
  {
    text:
      mode === "create"
        ? strings.header.createClient
        : strings.header.editClient,
  },
];
