import { Column } from "react-table";
import { reportStrings as strings } from "../../resources/strings/reports";
import { ReportData, ReportFilters, TagData } from "../../types/documents";

export const tableColumns = (
  propertyName: string,
  tags: TagData[],
  dateRange: ReportFilters
): Column<ReportData>[] => [
  {
    id: "value",
    Header: propertyName,
    accessor: ({ value }) =>
      tags.find((tag) => tag.id === value)?.text || value,
    disableSortBy: true,
  },
  {
    id: "start",
    Header: dateRange.start ?? strings.header.start,
    accessor: "startCount",
    maxWidth: 100,
    disableSortBy: true,
  },
  {
    id: "count",
    Header: strings.header.change,
    accessor: ({ startCount, endCount }) => endCount - startCount,
    maxWidth: 100,
    disableSortBy: true,
  },
  {
    id: "end",
    Header: dateRange.end ?? strings.header.end,
    accessor: "endCount",
    maxWidth: 100,
    disableSortBy: true,
  },
  {
    id: "order",
    width: 0,
    accessor: ({ value }) =>
      tags.find((tag) => tag.id === value)?.order ?? Number.MAX_VALUE,
    disableSortBy: true,
    sortType: "basic",
  },
];
