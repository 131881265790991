import { useQuery } from "@tanstack/react-query";
import { logger } from "../../helpers/log-helpers";
import type { ReadFunction } from "../../types/API";
import { HooksLogger } from "../hooks-logger";

const hooksLogger = new HooksLogger("useReadQuery");

export const useReadQuery = <D extends object>(
  defaultValue: D,
  queryKey: string,
  readFunction: ReadFunction<D>,
  id?: string,
  childId?: string
) => {
  const {
    data,
    isFetching: loading,
    error,
  } = useQuery<{ item: D }>({
    queryKey: [queryKey, id, childId],
    staleTime: Infinity,
    placeholderData: { item: defaultValue },
    enabled: Boolean(id),
    queryFn: async ({ signal }) => readFunction(id ?? "", childId, signal),
    onSuccess: ({ item }) => {
      hooksLogger.success(item);
    },
    onError: (e) => {
      const message = logger.error(e);
      hooksLogger.error(message);
    },
  });

  return [data ? data.item : defaultValue, loading, error] as const;
};
