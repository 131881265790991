import type { SxProps, Theme } from "@mui/material";
import { FormControl } from "@mui/material";

interface Props {
  children: JSX.Element | null;
  control?: boolean;
  margin?: boolean;
  padding?: boolean;
}
type Component = (props: Props) => JSX.Element;

const paddingStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(0, 2.5),
});

const marginStyles: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(1.5, 0),
});

export const BaseControl: Component = ({
  children,
  control = true,
  margin = false,
  padding = false,
}) => {
  let addMargin = margin;
  let addPadding = padding;

  if (control) {
    addMargin = true;
    addPadding = true;
  }

  return (
    <FormControl
      sx={[addPadding && paddingStyles, addMargin && marginStyles]}
      fullWidth
      color="primary"
    >
      {children}
    </FormControl>
  );
};
