import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export const FirstPageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Group-8" transform="translate(6.400000, 8.000000)">
        <path
          d="M6.43325776,5.64101861 L2.58974007,1.79800706 C2.53495018,1.74309063 2.4716824,1.71537934 2.39993673,1.71537934 C2.32882375,1.71537934 2.26555597,1.74309063 2.21013339,1.79800706 L-1.63287816,5.64101861 C-1.68766806,5.69631465 -1.71537934,5.75958243 -1.71537934,5.83082195 C-1.71537934,5.90244108 -1.68766806,5.96570886 -1.63287816,6.0206253 L-1.2206253,6.43275162 C-1.16570886,6.48766806 -1.10244108,6.51537934 -1.03082195,6.51537934 C-0.959582433,6.51537934 -0.896314652,6.48766806 -0.841018611,6.43275162 L2.39993673,3.19179628 L5.64152475,6.43275162 C5.69631465,6.4881742 5.75958243,6.51537934 5.83082195,6.51537934 C5.90244108,6.51537934 5.96570886,6.48766806 6.0206253,6.43275162 L6.43325776,6.0206253 C6.4881742,5.96570886 6.51537934,5.90244108 6.51537934,5.83082195 C6.51537934,5.75958243 6.4881742,5.69631465 6.43325776,5.64101861 Z"
          id="up-arrow-copy-6"
          transform="translate(2.400000, 4.115379) scale(-1, 1) rotate(-270.000000) translate(-2.400000, -4.115379) "
        ></path>
        <path
          d="M12.8332578,5.64101861 L8.98974007,1.79800706 C8.93495018,1.74309063 8.8716824,1.71537934 8.79993673,1.71537934 C8.72882375,1.71537934 8.66555597,1.74309063 8.61013339,1.79800706 L4.76712184,5.64101861 C4.71233194,5.69631465 4.68462066,5.75958243 4.68462066,5.83082195 C4.68462066,5.90244108 4.71233194,5.96570886 4.76712184,6.0206253 L5.1793747,6.43275162 C5.23429114,6.48766806 5.29755892,6.51537934 5.36917805,6.51537934 C5.44041757,6.51537934 5.50368535,6.48766806 5.55898139,6.43275162 L8.79993673,3.19179628 L12.0415248,6.43275162 C12.0963147,6.4881742 12.1595824,6.51537934 12.230822,6.51537934 C12.3024411,6.51537934 12.3657089,6.48766806 12.4206253,6.43275162 L12.8332578,6.0206253 C12.8881742,5.96570886 12.9153793,5.90244108 12.9153793,5.83082195 C12.9153793,5.75958243 12.8881742,5.69631465 12.8332578,5.64101861 Z"
          id="up-arrow-copy-7"
          transform="translate(8.800000, 4.115379) scale(-1, 1) rotate(-270.000000) translate(-8.800000, -4.115379) "
        ></path>
      </g>
    </SvgIcon>
  );
};
