import type { Permissions } from "../../types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { appStrings } from "../../resources/strings/app";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Button } from "./Button";

interface Props {
  permissions: Permissions | null;
  config: {
    entity: string;
    label: string;
    heading: string;
    message: string;
  };
  handleDelete: () => Promise<void>;
}
type Component = (props: Props) => JSX.Element;

export const DeleteEntityButton: Component = ({
  permissions,
  handleDelete,
  config: { entity, label, heading, message },
}) => {
  const [open, setOpen] = useState(false);

  const showConfirmDialog = () => setOpen(true);
  const hideConfirmDialog = () => setOpen(false);

  return (
    <ShowIfAuthorised
      userPermissions={permissions}
      permission={appStrings.permissions.delete}
      entity={entity}
    >
      <Button
        label={label}
        variant="outlined"
        color="error"
        onClick={showConfirmDialog}
      />
      <Dialog open={open} onClose={hideConfirmDialog}>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            label={"Cancel"}
            onClick={hideConfirmDialog}
            variant="outlined"
          />
          <Button label={label} color="error" onClick={handleDelete} />
        </DialogActions>
      </Dialog>
    </ShowIfAuthorised>
  );
};
