import { useQuery } from "@tanstack/react-query";
import { logger } from "../../helpers/log-helpers";
import type { QueryFunction } from "../../types/API";
import { HooksLogger } from "../hooks-logger";

const hooksLogger = new HooksLogger("useQuery");

const defaultBody = {};

const useCustomQuery = <D extends object>(
  defaultValue: Array<D>,
  queryKey: string,
  queryFunction: QueryFunction<D>,
  // be advised that if this body argument isnt from state or is defined
  // inside the scope of the component, it will likely cause an infinite loop
  body = defaultBody
) => {
  const {
    data,
    isFetching: loading,
    error,
  } = useQuery<{ items: D[] }>({
    queryKey: [queryKey],
    placeholderData: { items: defaultValue },
    staleTime: Infinity,
    queryFn: async ({ signal }) => queryFunction(body, signal),
    onSuccess: ({ items }) => {
      hooksLogger.success(items);
    },
    onError: (e) => {
      const message = logger.error(e);
      hooksLogger.error(message);
    },
  });

  return [data ? data.items : defaultValue, loading, error] as const;
};

export { useCustomQuery as useQuery };
