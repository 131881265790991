import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Divider,
  Drawer,
  drawerClasses,
  IconButton,
  List,
  ListItemButton,
  listItemClasses,
  ListItemIcon,
  ListItemText,
  styled,
  SxProps,
  Theme,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { MenuItem } from "./listItems";

interface Props {
  children: JSX.Element;
  menuItems: MenuItem[];
  permissions: Permissions | null;
  logo: string;
  additionalContent?: JSX.Element;
}
type Component = (props: Props) => JSX.Element;

interface MenuItemsProps {
  menuItems: MenuItem[];
  permissions: Permissions | null;
}
type MenuItemsComponent = (props: MenuItemsProps) => JSX.Element;

const drawerWidth = 240;
const smallDrawerWidth = 0;

const rootStyles: SxProps<Theme> = {
  display: "flex",
};

const toolbarStyles: SxProps<Theme> = {};

const toolbarHeaderStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 0.5rem",
};

const appBarStyles: SxProps<Theme> = (theme) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const menuButtonStyles: SxProps<Theme> = (theme) => ({
  marginLeft: theme.spacing(10.5),
});

const flexSpacerStyles: SxProps<Theme> = {
  flexGrow: 1,
};

const drawerPaperStyles: SxProps<Theme> = (theme) => ({
  overflowX: "hidden",
  [`& .${drawerClasses.paper}`]: {
    overflowX: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("xs")]: {
      width: smallDrawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const drawerPaperCloseStyles: SxProps<Theme> = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("xs")]: {
    width: smallDrawerWidth,
  },
  [theme.breakpoints.up("md")]: {
    width: theme.spacing(7),
  },
  [`& .${drawerClasses.paper}`]: {
    overflowX: "hidden",
    [theme.breakpoints.up("xs")]: {
      width: smallDrawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
    },
  },
});

const appBarSpacerStyles: SxProps<Theme> = (theme) => theme.mixins.toolbar;

const contentStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
  overflow: "auto",
};

const mediaStyles: SxProps<Theme> = (theme) => ({
  height: theme.spacing(7.5),
  width: theme.spacing(15),
  marginLeft: theme.spacing(2.5),
  backgroundSize: "contain",
});

const selectedItemStyles: SxProps<Theme> = (theme) => ({
  borderLeft: theme.spacing(0.5, "solid", "transparent"),
  [`&.${listItemClasses.selected}`]: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.common.lightgrey,
    "&:hover": {
      backgroundColor: theme.palette.common.grey,
    },
  },
});

const selectedTextStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.darkgrey,
});

const selectedIconStyles: SxProps<Theme> = (_theme) => ({
  color: "primary.main",
});

const textStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.altgrey,
  fontWeight: "bolder",
});

const iconStyles: SxProps<Theme> = (theme) => ({
  color: theme.palette.common.altgrey,
});

const Div = styled("div")({});

// as of writing, there seems to be a bug with the toolbar mixin when used with sx
// apply it using the styled API instead
const ToolbarDiv = styled("div")(({ theme }) => theme.mixins.toolbar);

const Main = styled("main")({});

const MenuItems: MenuItemsComponent = ({ menuItems, permissions }) => {
  const [selected, setSelected] = useState(-1);
  const history = useHistory();

  return (
    <List>
      <div>
        {menuItems.map(({ label, Icon, itemPermission, link }, index) => {
          const isSelected = selected === index;
          const handleClick = () => {
            setSelected(index);
            history.push(link);
          };
          const Content = (
            <ListItemButton
              sx={[selectedItemStyles]}
              selected={isSelected}
              onClick={handleClick}
            >
              <ListItemIcon sx={[iconStyles, isSelected && selectedIconStyles]}>
                <Icon />
              </ListItemIcon>
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  sx: [textStyles, isSelected && selectedTextStyles],
                }}
              />
            </ListItemButton>
          );

          return (
            <React.Fragment key={`${label}-${index}`}>
              {itemPermission ? (
                <ShowIfAuthorised
                  userPermissions={permissions}
                  {...itemPermission}
                >
                  {Content}
                </ShowIfAuthorised>
              ) : (
                <>{Content}</>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </List>
  );
};

export const MenuComponent: Component = ({
  children,
  menuItems,
  permissions,
  logo,
  additionalContent,
}) => {
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Div sx={[rootStyles]} role="menu">
      <AppBar position="absolute" color="secondary" sx={[appBarStyles]}>
        <Toolbar sx={[toolbarStyles]}>
          <CardMedia sx={[mediaStyles]} image={logo} />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggle}
            sx={[menuButtonStyles]}
            data-testid="toggle-button"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Box color="inherit" sx={[flexSpacerStyles]}></Box>
          {additionalContent ? additionalContent : null}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={[drawerPaperStyles, !open && drawerPaperCloseStyles]}
        open={open}
      >
        <ToolbarDiv sx={[toolbarHeaderStyles]} />
        <Divider hidden />
        <MenuItems menuItems={menuItems} permissions={permissions} />
      </Drawer>
      <Main sx={[contentStyles]}>
        <Div sx={[appBarSpacerStyles]} />
        <Container maxWidth={false} disableGutters>
          {children}
        </Container>
      </Main>
    </Div>
  );
};
