import type { SxProps, Theme } from "@mui/material";
import {
  Box,
  Grid,
  Paper,
  TableBody,
  TableCell,
  Table as TableComponent,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import type { Row, SortingRule, TableOptions } from "react-table";
import { useFlexLayout, usePagination, useSortBy, useTable } from "react-table";
import { Permissions, UserAccess } from "../../types";
import { Loader } from "../general/Loader";
import { BreadcrumbHeader } from "./BreadcrumbHeader";
import { CreateEntityButton } from "./CreateEntityButton";
import { TablePaginationActions } from "./TablePaginationActions";

/*
  The react table docs are useless when it comes to typescript

  Some useful resources:
    - Type declarations: 
        https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
    - For future projects, pay particular attention to the configuration 
      section of the readme in the above repo
    - React table example written in TypeScript from one of the React Table maintainers
        https://github.com/ggascoigne/react-table-example
*/

type TableEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;

interface Props<D extends Record<string, unknown>> extends TableOptions<D> {
  header?: string;
  totalCount?: number;
  size?: number;
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  permissions: Permissions | null;
  loading?: boolean;
  clickHandler?: (data: D) => void;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  children?: JSX.Element | Array<JSX.Element>;
  inlineLoader?: boolean;
  topBorder?: boolean;
  hidePagination?: boolean;
  dense?: boolean;
  defaultSort?: SortingRule<D>[];
}

const Div = styled("div")({});

const rootStyles: SxProps<Theme> = {
  boxShadow: "none",
  borderRadius: 0,
};

const bodyStyles: SxProps<Theme> = (theme) => ({
  "&:nth-of-type(even)": {
    background: theme.palette.common.lightgrey,
  },
});

const clickStyles: SxProps<Theme> = {
  "&:hover": {
    cursor: "pointer",
  },
};

const headStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.lightgrey,
  color: theme.palette.common.darkgrey,
  fontWeight: "bolder",
});

const borderStyles: SxProps<Theme> = (theme) => ({
  borderTop: theme.spacing(0.1, "solid", theme.palette.common.grey!),
});

const childrenStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
});

export const Table = <T extends Record<string, unknown>>({
  header,
  data: tableData,
  columns: tableColumns,
  totalCount = tableData.length,
  size = 10,
  createButtonConfig,
  permissions,
  loading,
  clickHandler,
  manualPagination,
  onPageChange,
  onSizeChange,
  children,
  inlineLoader,
  topBorder,
  hidePagination,
  dense,
  defaultSort = [],
}: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const pageCount = useMemo(
    () => Math.ceil(totalCount / size),
    [totalCount, size]
  );

  const hooks = [useSortBy, usePagination, useFlexLayout];

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: size,
        pageIndex: 0,
        sortBy: defaultSort,
      },
      manualPagination,
      pageCount,
      // this flag prevents weird behaviour when changing page
      // like fetching the next page then re-fetching the previous page immediately
      autoResetPage: false,
    },
    ...hooks
  );

  // NOTE: if a new hook plugin is used, it may be necessary to update
  // `types/react-table-config.d.ts` to get the correct types
  // See `TableOptions` interface
  const {
    // basic table props
    getTableProps,
    getTableBodyProps,
    headerGroups = [],
    prepareRow,
    state: { pageIndex, pageSize: tablePageSize },
    // pagination props
    page: pageRows = [],
    gotoPage,
  } = instance;

  // If manual pagination is set, we want to forward any changes in table state
  // to trigger a new data fetch
  useEffect(() => {
    if (manualPagination) {
      onPageChange(pageIndex);
      onSizeChange(tablePageSize);
    }
  }, [manualPagination, pageIndex, tablePageSize, onPageChange, onSizeChange]);

  const handleChangePage = (_event: TableEvent, newPage: number) => {
    gotoPage(newPage);
  };

  const renderCreateButton = () => {
    if (!createButtonConfig) return <></>;
    return (
      <CreateEntityButton
        config={createButtonConfig}
        permissions={permissions}
      />
    );
  };

  const renderHeader = () => {
    if (!header) return null;
    return (
      <BreadcrumbHeader
        crumbs={[{ text: header }]}
        children={renderCreateButton()}
      />
    );
  };

  const handleClick = (row: Row<T>) => {
    if (clickHandler) clickHandler(row.original);
  };

  const renderTableHead = () => (
    <TableHead component="span">
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()} component="span">
          {headerGroup.headers.map((column) => (
            <TableCell
              {...column.getHeaderProps(column.getSortByToggleProps())}
              component="span"
              sx={[
                headStyles,
                Boolean(children || topBorder || !header) && borderStyles,
              ]}
            >
              {column.render("Header")}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );

  const renderTableBody = () => (
    <Loader active={loading && inlineLoader} inline>
      <TableBody {...getTableBodyProps()} component="span">
        {pageRows.map((row) => {
          prepareRow(row);
          const isClickable = Boolean(clickHandler);
          return (
            <TableRow
              {...row.getRowProps()}
              onClick={() => handleClick(row)}
              hover
              sx={[bodyStyles, isClickable && clickStyles]}
              component="span"
            >
              {row.cells.map((cell) => (
                <TableCell {...cell.getCellProps()} component="span">
                  <Typography noWrap variant="body2">
                    {cell.render("Cell")}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Loader>
  );

  const renderTablePagination = () =>
    !hidePagination && (
      <TablePagination
        role="navigation"
        component="div"
        page={pageIndex}
        count={totalCount}
        rowsPerPageOptions={[]}
        rowsPerPage={tablePageSize}
        onPageChange={handleChangePage}
        SelectProps={{ title: "page options" }}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} to ${to} of ${count}`
        }
        ActionsComponent={TablePaginationActions}
      />
    );

  const renderTableChildren = () => {
    if (!children) return null;
    return (
      <Div data-testid="table-children" sx={[childrenStyles]}>
        <Grid container>{children}</Grid>
      </Div>
    );
  };

  const renderTable = () => {
    return (
      <>
        {renderTableChildren()}
        <TableContainer component={Paper} sx={[rootStyles]}>
          <TableComponent
            {...getTableProps()}
            component="span"
            size={dense ? "small" : "medium"}
          >
            {renderTableHead()}
            {renderTableBody()}
          </TableComponent>
        </TableContainer>
        {renderTablePagination()}
      </>
    );
  };

  return (
    <Box role="grid" display="flex" flexDirection="column">
      {renderHeader()}
      <Loader active={loading && !inlineLoader}>{renderTable()}</Loader>
    </Box>
  );
};
