import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import type { Column, SortingRule } from "react-table";
import { Table } from "../../components/general/Table";
import { logger } from "../../helpers/log-helpers";
import { HooksLogger } from "../../hooks/hooks-logger";
import type { RouteProps, UserAccess } from "../../types";
import type { QueryBody, QueryFunction } from "../../types/API";

const hooksLogger = new HooksLogger("TableContainer/useEffect");

interface Props<D extends object> extends RouteProps {
  api: {
    query: QueryFunction<D>;
  };
  queryKey: string;
  header?: string;
  tableColumns: Column<D>[];
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  filters?: Partial<D>;
  children?: JSX.Element | Array<JSX.Element>;
  inlineLoader?: boolean;
  topBorder?: boolean;
  hidePagination?: boolean;
  dense?: boolean;
  defaultSort?: SortingRule<D>[];
}

export const TableContainer = <D extends Record<string, unknown>>({
  api,
  queryKey,
  permissions,
  header,
  tableColumns,
  createButtonConfig,
  clickHandler,
  manualPagination,
  filters,
  children,
  inlineLoader,
  topBorder,
  hidePagination,
  dense,
  defaultSort,
}: Props<D>) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  const searchCritera = useMemo(
    () => ({
      page,
      size,
    }),
    [page, size]
  );

  const { data, isFetching: loading } = useQuery<{
    items: D[];
    totalCount: number;
  }>({
    queryKey: [queryKey, page, size, filters],
    placeholderData: { items: [], totalCount: 0 },
    queryFn: async ({ signal }) => {
      const body: QueryBody<D> = manualPagination ? searchCritera : {};
      if (filters) {
        body.query = filters;
      }
      return api.query(body, signal);
    },
    onSuccess: ({ items, totalCount }) => {
      hooksLogger.success(items, totalCount);
    },
    onError: (e) => {
      const error = logger.error(e);
      hooksLogger.error(error);
    },
  });

  const onPageChange = useCallback((pageIndex: number) => {
    setPage(pageIndex);
  }, []);

  const onSizeChange = useCallback((pageSize: number) => {
    setSize(pageSize);
  }, []);

  if (!data) return <></>;

  return (
    <div>
      <Table<D>
        // custom props
        permissions={permissions}
        header={header}
        createButtonConfig={createButtonConfig}
        loading={loading}
        clickHandler={clickHandler}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        children={children}
        inlineLoader={inlineLoader}
        topBorder={topBorder}
        hidePagination={hidePagination}
        dense={dense}
        // react table props
        data={data.items}
        columns={tableColumns}
        size={size}
        manualPagination={manualPagination}
        totalCount={data.totalCount}
        defaultSort={defaultSort}
      />
    </div>
  );
};
