import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export const DownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.08212 10.4744L11.9256 14.3174C11.9804 14.3723 12.0437 14.4 12.1154 14.4C12.1866 14.4 12.2498 14.3723 12.3052 14.3174L16.1483 10.4744C16.203 10.4191 16.2308 10.3558 16.2308 10.2846C16.2308 10.2129 16.203 10.1497 16.1483 10.0948L15.736 9.68264C15.6811 9.62772 15.6178 9.60001 15.5462 9.60001C15.475 9.60001 15.4117 9.62772 15.3564 9.68264L12.1154 12.9236L8.87385 9.68264C8.81906 9.62721 8.7558 9.60001 8.68456 9.60001C8.61294 9.60001 8.54967 9.62772 8.49475 9.68264L8.08212 10.0948C8.0272 10.1497 8 10.2129 8 10.2846C8 10.3558 8.0272 10.4191 8.08212 10.4744Z" />
    </SvgIcon>
  );
};
