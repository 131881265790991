import { useState, useEffect } from "react";
import { Permissions } from "../../types";

export const useIsAuthorised = (
  // permissions may end up coming from store rather than
  // passing in each time
  permissions: Permissions | null,
  entity: string,
  permission: string
) => {
  const [isAuthorised, setIsAuthorised] = useState(false);

  useEffect(() => {
    if (permissions) {
      let allowedPermissions: string[] = [];
      if (permissions[entity]) {
        allowedPermissions = permissions[entity];
      }

      if (allowedPermissions.includes(permission)) {
        setIsAuthorised(true);
      }
    }
  }, [permissions, entity, permission]);

  return isAuthorised;
};
