import { logger } from "../helpers/log-helpers";
import axios from "../libs/axios/axios-tags";
import type { ReadFunction, ReadResult } from "../types/API";
import type { TagItem } from "../types/documents/Tag";

const read: ReadFunction<TagItem> = async (id, _childId, signal?) => {
  try {
    const path = `/${id}`;
    const { data } = await axios.get<ReadResult<TagItem>>(path, {
      signal,
    });
    return Promise.resolve(data);
  } catch (e) {
    const message = logger.error(e);
    return Promise.reject(message);
  }
};

export const api = {
  read,
};
