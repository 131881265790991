import axios from "../libs/axios/axios-permissions";
import type { Permissions } from "../types";

import type { QueryFunctionContext } from "@tanstack/react-query";
import { logger } from "../helpers/log-helpers";

type Response = {
  userPermissions: Permissions;
};

const read = async (context: QueryFunctionContext): Promise<Permissions> => {
  try {
    const { signal } = context;
    const { data } = await axios.get<Response>("", {
      signal,
    });
    return Promise.resolve(data.userPermissions);
  } catch (e) {
    const message = logger.error(e);
    return Promise.reject(message);
  }
};

export const api = {
  read,
};
